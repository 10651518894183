<template>
    <component :is="layout">
      <router-view/>
    </component>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import EmptyLayout from "@/layouts/EmptyLayout";
export default {
  name: 'App',
  components: {
    'main-layout' : MainLayout,
    'empty-layout' : EmptyLayout,
  },
  computed:{
    layout(){
      if (this.$route.meta.layout === 'empty') return 'empty-layout';
      if (this.$route.meta.layout === 'main') return 'main-layout';
      return 'main-layout';
    }
  },
  created() {
    console.log('ok')
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
