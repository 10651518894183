import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthRoutes from './auth'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about')
  },
  {
    path: '/course',
    name: 'course',
    component: () => import('../views/course'),
    children:[
      {
        path: '/list',
        name: 'CourseList',
        component: () => import('../views/course/list'),
      },
      {
        path: '/details',
        name: 'CourseDetails',
        component: () => import('../views/course/details'),
      }
    ]
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/blog'),
    children:[
      {
        path: '/blog/list',
        name: 'BlogList',
        component: () => import('../views/blog/list')
      },
      {
        path: '/blog/details',
        name: 'BlogDetails',
        component: () => import('../views/blog/details')
      }
    ]
  },
  ...AuthRoutes
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
