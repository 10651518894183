<template>
  <!--  footer-->
  <section class="footer footer-3 pt-250">
    <div class="footer-mid">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-sm-8 me-auto">
            <div class="widget footer-widget mb-5 mb-lg-0">
              <div class="footer-logo">
                <img src="../../assets/images/logo-white.png" alt="" class="img-fluid">
              </div>

              <p class="mt-4">Edumel is a Bootstrap Template for online courses
                education websites support multiple courses</p>

              <div class="footer-socials mt-5">
                <span class="me-2">Connect :</span>
                <a href="#"><i class="fab fa-facebook-f"></i></a>
                <a href="#"><i class="fab fa-twitter"></i></a>
                <a href="#"><i class="fab fa-linkedin-in"></i></a>
                <a href="#"><i class="fab fa-pinterest"></i></a>
              </div>
            </div>
          </div>

          <div class="col-xl-2 col-sm-4">
            <div class="footer-widget mb-5 mb-lg-0">
              <h5 class="widget-title">Explore</h5>
              <ul class="list-unstyled footer-links">
                <li><a href="#">About us</a></li>
                <li><a href="#">Contact us</a></li>
                <li><a href="#">Services</a></li>
                <li><a href="#">Support</a></li>
              </ul>
            </div>
          </div>

          <div class="col-xl-2 col-sm-4">
            <div class="footer-widget mb-5 mb-lg-0">
              <h5 class="widget-title ">Programs</h5>
              <ul class="list-unstyled footer-links">
                <li><a href="#">SEO Business</a></li>
                <li><a href="#">Digital Marketing</a></li>
                <li><a href="#">Graphic Design</a></li>
                <li><a href="#">Social Marketing</a></li>
              </ul>
            </div>
          </div>

          <div class="col-xl-2 col-sm-4">
            <div class="footer-widget mb-5 mb-lg-0">
              <h5 class="widget-title">Links</h5>
              <ul class="list-unstyled footer-links">
                <li><a href="#">News & Blogs</a></li>
                <li><a href="#">Privacy Policy</a></li>
                <li><a href="#">Support</a></li>
                <li><a href="#">Return Policy</a></li>
              </ul>
            </div>
          </div>

          <div class="col-xl-2 col-sm-4">
            <div class="footer-widget mb-5 mb-lg-0">
              <h5 class="widget-title">Address</h5>
              <ul class="list-unstyled footer-links">
                <li><a href="#">+0800 327 8534</a></li>
                <li><a href="#">support@edumel.com</a></li>
                <li><a href="#">123 Fifth Floor East 26th Street,New York, NY 10011</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-btm">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-6 col-sm-12 col-lg-6">
            <p class="mb-0 copyright text-sm-center text-lg-start">© 2021 Edumel All rights reserved by <a href="https://themeturn.com" rel="nofollow">Dreambuzz</a> </p>
          </div>

          <div class="col-xl-6 col-sm-12 col-lg-6">
            <div class="footer-btm-links text-start text-sm-center text-lg-end">
              <ul class="list-inline">
                <li class="list-inline-item"><a href="#"> Terms of Service</a></li>
                <li class="list-inline-item"><a href="#">Join Us</a></li>
                <li class="list-inline-item"><a href="#">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="fixed-btm-top">
      <a href="#top-header" class="js-scroll-trigger scroll-to-top"><i class="fa fa-angle-up"></i></a>
    </div>

  </section>
</template>

<script>
export default {
  name: "FooterLayout"
}
</script>

<style scoped>

</style>
