<template>
  <div>
    <header-layout/>
    <router-view/>
    <footer-layout/>
  </div>
</template>

<script>
import HeaderLayout from "@/layouts/components/Header";
import FooterLayout from "@/layouts/components/Footer";
export default {
  name: "MainLayout",
  components:{
    'header-layout': HeaderLayout,
    'footer-layout': FooterLayout,
  }
}
</script>

<style scoped>

</style>
