<template>
  <!--  header-->
  <header class="header-style-1">
    <div class="header-topbar topbar-style-2">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-xl-8 col-lg-6 col-md-12">
            <div class="header-contact text-center text-lg-start d-none d-sm-block">
              <ul class="list-inline">
                <li class="list-inline-item">
                  <span class="text-color me-2"><i class="fa fa-phone-alt"></i></span><a href="tel:+(354) 6800 37849"> +(354) 6800 37849</a>
                </li>

                <li class="list-inline-item">
                  <span class="text-color me-2"><i class="fa fa-envelope"></i></span><a href="malito:hello@edumel.com"> hello@edumel.com</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-xl-4 col-lg-6 col-md-12">
            <div class="d-sm-flex justify-content-center justify-content-lg-end">
              <div class="header-socials text-center text-lg-end">
                <ul class="list-inline">
                  <li class="list-inline-item"><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                  <li class="list-inline-item"><a href="#"><i class="fab fa-twitter"></i></a></li>
                  <li class="list-inline-item"><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                  <li class="list-inline-item"><a href="#"><i class="fab fa-pinterest"></i></a></li>
                  <li class="list-inline-item"><a href="#"><i class="fab fa-youtube"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="header-navbar navbar-sticky">
      <div class="container">
        <div class="d-flex align-items-center justify-content-between">
          <div class="site-logo">
            <router-link :to="{name:'home'}">
              <img src="../../assets/images/logo.png" alt="" class="img-fluid" />
            </router-link>
          </div>

          <nav class="site-navbar ms-auto">

            <ul class="primary-menu">
              <li class="current">
                <router-link :to="{ name:'home' }">Home</router-link>
              </li>
              <li><router-link :to="{ name:'about' }">About</router-link></li>
              <li>
                <a href="javascript:void(0);">Courses</a>
                <ul class="submenu">
                  <li><router-link :to="{ name:'CourseList' }">Courses List</router-link></li>
                  <li><router-link :to="{ name:'CourseDetails' }">Course Details</router-link></li>
                </ul>
              </li>

              <li>
                <a href="javascript:void(0);">Blog</a>
                <ul class="submenu">
                  <li><router-link :to="{ name:'BlogList' }">Blogs</router-link></li>
                  <li><router-link :to="{ name:'BlogDetails' }">Blog Details</router-link></li>
                </ul>
              </li>
              <li>
                <router-link :to="{ name: 'contact' }">Contact</router-link>
              </li>
            </ul>

          </nav>
          <div class="header-btn d-none d-xl-block">
            <router-link :to="{ name:'login' }" class="login">Login</router-link>
            <router-link :to="{ name:'register' }" class="btn btn-main-2 btn-sm-2 rounded">Sign up</router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderLayout"
}
</script>

<style scoped>

</style>
