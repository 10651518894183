export default [
  {
    path:'/',
    name:'auth',
    component: () => import(/* webpackChunkName: "login" */ '../views/auth'),
    children:[
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/auth/login.vue'),
      },
      {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "register" */ '../views/auth/register.vue'),
      },
    ]
  },
]
